/* eslint-disable no-console */
import { version } from "../../../package.json";
import { useCallback, useEffect } from "react";

export const CacheBuster = ({ children }) => {
  const clearCacheWhenNewVersion = useCallback(() => {
    void fetch("/app.json").then(
        (response) => response?.json()
      ).then((appDetails) => {
        if (appDetails.version != version) {
          caches
            .keys()
            .then(async (names) => {
              for (const name of names) {
                await caches.delete(name);
              }
            })
            .finally(() => setTimeout(() => {
              window.location.reload();
            }, 1000));
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    clearCacheWhenNewVersion();
    // Review it each 2 hours
    setInterval(() => clearCacheWhenNewVersion(), 120 * 60 * 1000);
  }, [clearCacheWhenNewVersion]);

  return children;

};
